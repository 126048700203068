import React, { useState, useEffect } from "react";
import "./contact_page.css";
import phone_logo from "../Images/phone logo.png";
import email_logo from "../Images/email1_logo.png";
import office_logo from "../Images/office_logo.png";
import emailjs from "emailjs-com";
import "react-notifications/lib/notifications.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

function Contact_page() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 2000 });
  }, []);

  const initialFormData = {
    name: "",
    email: "",
    subject: "",
    phone_no: "",
    message: "",
  };

  const [formData, setFormData] = useState({ initialFormData });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_a5akknd",
        "template_p0o5msn",
        formData,
        "jWRLqRC1XVnUDRA24"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);

          toast.success(
            "Thank you for contacting us! We will get back to you shortly."
          );
          setFormData(initialFormData);
        },
        (error) => {
          console.log("FAILED...", error);
          toast.error(
            "Error Sending Message, Please try again later or try contacting using Email."
          );
          setFormData(initialFormData);
        }
      );
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Galaxy Line Contracting Company</title>
        <meta
          name="description"
          content="Get in touch with Galaxy Line Contracting Company for inquiries, quotes, and consultations. Our team is ready to assist with your construction needs."
        />
        <meta
          name="keywords"
          content="contact Galaxy Line, contact GLCC, contact number GLCC, email GLCC,construction inquiries, construction quotes, consultations, construction company contact"
        />
        <link rel="canonical" href="https://www.glccsa.com/contact" />
      </Helmet>
      <div className="page_title">
        <div className="background-overlay">
          <h1 data-aos="fade-up">Contact Us</h1>
          <p data-aos="fade-up">Feel Free to ask any Question</p>
        </div>
      </div>

      <div className="contact_items">
        <div className="phone_div carrds" data-aos="fade-up">
          <img src={phone_logo} alt="" />
          <h1>Phone No</h1>
          <p className="p">We'd love to hear your inquiries via phone.</p>
          <p className="p">
            Ph: <span>(+966) 53 5060547</span>
          </p>
          <p className="ph2">
            Ph: <span>(+966) 50 7592076</span>
          </p>
        </div>
        <div className="email_div carrds" data-aos="fade-up">
          <img src={email_logo} alt="" />
          <h1>Email</h1>
          <p className="p">
            Having inquiries? Email Us, we will try to reply you as soon as
            possible
          </p>
          <p className="p">
            {" "}
            <span>info@glccsa.com</span>
          </p>
        </div>
        <div className="address_div carrds" data-aos="fade-up">
          <img src={office_logo} alt="" />
          <h1>Address</h1>
          <p className="p">
            Jeddah, Faisaliyah, Al Awwad Center, Po box 23442&nbsp; &nbsp;
            Kingdom of Saudi Arabia
          </p>
        </div>
      </div>
      <div className="contact" id="">
        <div id="contact_id" data-aos="fade-up">
          <h1>
            Quick <span>inquiry!</span>
          </h1>
          <div className="line"></div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="form_contact"
          data-aos="fade-up"
        >
          <input
            type="text"
            name="name"
            placeholder="Name*"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <div className="email_phone_group">
            <input
              type="email"
              name="email"
              placeholder="Email*"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone_no"
              placeholder="Phone no*"
              value={formData.phone_no}
              onChange={handleChange}
              required
            />
          </div>
          <input
            type="subject"
            name="subject"
            placeholder="Subject*"
            value={formData.subject}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            rows="6"
            placeholder="Message*"
            value={formData.message}
            onChange={handleChange}
            style={{ resize: "none" }}
          ></textarea>
          <button type="submit" className="btn btn2 btn_submit">
            Submit
          </button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}

export default Contact_page;
