import React, { useState } from "react";
import "./service_dropdown.css";
import { servicesDropdown } from "./Nav_items";
import { Link } from "react-router-dom";

function ServicesDropdown({ onLinkClick, menu }) {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      {menu === "false" ? (
        <div
          className={dropdown ? "service_dropdown clicked" : "service_dropdown"}
          onClick={() => setDropdown(!dropdown)}
        >
          {servicesDropdown.map((item, index) => (
            <Link
              to={item.path}
              className="dropdown-link"
              key={index}
              onClick={onLinkClick}
            >
              <article id="items">
                <img src={item.img} alt={item.title} />
                <h1>{item.title}</h1>
              </article>
            </Link>
          ))}
        </div>
      ) : (
        <div className="services_mobile_dropdown">
          {servicesDropdown.map((item, index) => {
            return (
              <Link
                to={item.path}
                className="dropdown-link"
                key={index}
                onClick={onLinkClick}
              >
                <h1>- {item.title}</h1>
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
}

export default ServicesDropdown;
