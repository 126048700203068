import general_contracting from "../Services_images/general_contracting.jpg";
import concrete_pavement from "../Services_images/Concrete_Pavement.jpg";
import structural_construction from "../Services_images/Structural_construction.jpg";
import Waterproofing_solution from "../Services_images/Waterproofing_solutions.jpg";
import finishing_work from "../Services_images/Finishing_work.jpg";
import earth_work from "../Services_images/earth_work.jpg";
import electrical_services from "../Services_images/Electrical_services.jpg";
import plumbing_services from "../Services_images/plumbing_services.jpg";
import equipment_rental from "../Services_images/equipment_rental.jpg";
import Fence_installation from "../Services_images/fence_installation.jpg";
import general_contracting1 from "../Services_images/general_contracting1.jpg";
import concrete_pavement1 from "../Services_images/Concrete_Pavement1.jpg";
import structural_construction1 from "../Services_images/Structural_construction1.jpg";
import Waterproofing_solution1 from "../Services_images/Waterproofing_solutions1.jpg";
import finishing_work1 from "../Services_images/Finishing_work1.jpg";
import earth_work1 from "../Services_images/earth_work1.jpg";
import electrical_services1 from "../Services_images/Electrical_services1.jpg";
import plumbing_services1 from "../Services_images/plumbing_services1.jpg";
import equipment_rental1 from "../Services_images/equipment_rental1.jpg";
import Fence_installation1 from "../Services_images/fence_installation1.jpg";

export const aboutDropdown = [
  {
    id: 1,
    title: "Introduction",
    path: "./about#introduction",
    cname: "submenu-item",
  },
  {
    id: 2,
    title: "Mission and Vision",
    path: "./about#mission_vision",
    cname: "submenu-item",
  },
  {
    id: 3,
    title: "Quality Policy",
    path: "./about#quality_policy",
    cname: "submenu-item",
  },
  {
    id: 4,
    title: "Our Goals",
    path: "./about#our_goals",
    cname: "submenu-item",
  },
  {
    id: 5,
    title: "Safety Policy",
    path: "./about#safety_policy",
    cname: "submenu-item",
  },
];

export const servicesDropdown = [
  {
    id: "general_contracting",
    title: "General Contracting",
    tagline: "Consider Your Commercial Outdoor Job Needs Solved",
    description:
      "We have a team of civil construction experts handling the civil work division which make us provide highly efficient and accurate services to our clients. Our team members are experienced & professionals, by which we reduce our uncertainties to the lowest level.",
    description1:
      "We have a hands-on management philosophy; a keen team player spirit and we pride ourselves on quality workmanship and the timely completion of projects. Depending on the nature and timing of the contracts, we can undertake any kind of project from small to medium size.",
    img: general_contracting,
    img1: general_contracting1,
    path: `/services/${"general_contracting"}`,
    cname: "service_submenu",
  },
  {
    id: "concrete_pavement",
    title: "Concrete Pavement",
    tagline: "Seasoned Asphalt & Concrete Paving Contractors",
    description:
      "Galaxy Line Contracting Co. offers asphalt and concrete pavement solutions at an affordable price. We are a concrete contractor committed to serving all areas across the Kingdom. We leverage the latest paving technologies, yielding parking lots, sidewalks, and roads that are smooth, safe, and reliable.",
    description1:
      "We provide services such as commercial parking lot repair, parking lot resurfacing, pothole repair, and more. Our team of pavement and asphalt contractors focuses on commitment and consultation throughout the entire project. We use methods that ensure completion on time and within budget.",
    img: concrete_pavement,
    img1: concrete_pavement1,
    path: `/services/${"concrete_pavement"}`,
    cname: "service_submenu",
  },
  {
    id: "structural_construction",
    title: "Structural Construction",
    tagline:
      "Providing you with innovative, cost-effective structural solutions for projects",
    description:
      "Experts in structural engineering, our consulting team have many years of experience and are happy to work closely with you to provide the ideal solution for your project. Ensuring all requirements are met and that you stay within budget.",
    description1:
      "We offer a comprehensive range of services in the areas of office and commercial development, refurbishment, industrial development, design and build projects, housing, underpinning and superstructure repairs/improvements, and structural surveys.",
    img: structural_construction,
    img1: structural_construction1,
    path: `/services/${"structural_construction"}`,
    cname: "service_submenu",
  },
  {
    id: "waterproofing_solution",
    title: "Waterproofing Solution",
    tagline: "We are routinely consulted for our expertise in waterproofing",
    description:
      "We are one of the leading waterproofing installers' services and materials; Based on years of waterproofing experience supported by an extensive product range that covers everything from high-performance roofing and basement systems. ",
    description1:
      "We undertake various waterproofing services by using the best quality raw materials and products. As a specialist in waterproofing, we offer extensive and efficient waterproofing system installation and repairs for protection from water. We have an experienced team to install waterproofing, maintain and repair, and solve leakage problems.",
    img: Waterproofing_solution,
    img1: Waterproofing_solution1,
    path: `/services/${"waterproofing_solution"}`,
    cname: "service_submenu",
  },
  {
    id: "finishing_works",
    title: "Finishing Works",
    tagline: "To raise the bar in the Finishing works.",
    description:
      "Galaxy Line has many years of experience in interior and exterior finishing works. Our company provides a wide range of repair and construction services. Our specialists use innovative technologies, up-to-date equipment, and modern building materials to achieve the best results.",
    description1:
      "Finishing work is a time-consuming and complex process, the result of which affects the building's appearance and performance. High-quality finishes are only possible when reliable materials are used, and competent specialists perform their work.",
    img: finishing_work,
    img1: finishing_work1,
    path: `/services/${"finishing_works"}`,
    cname: "service_submenu",
  },
  {
    id: "earth_work",
    title: "Earth Work Site Preparation",
    tagline: "We Prepare, Clear, And Dispose",
    description:
      "Our site preparation and clearing expertise offers comprehensive services to cater to your every requirement. With a proven track record, we have honed our skills, equipping us to seamlessly handle tasks ranging from bulk excavation to site remediation.",
    description1:
      "Earthworks sets the foundations for your project, let Galaxy Line take on your next Earthworks project. With our extensive and diverse plant machinery, our knowledge, and quality workmanship, we’re equipped to handle your next project wherever you are.",
    img: earth_work,
    img1: earth_work1,
    path: `/services/${"earth_work"}`,
    cname: "service_submenu",
  },

  {
    id: "electrical_services",
    title: "Electrical Services",
    tagline: "A complete range of expert electrical services",
    description:
      "We have successfully delivered many projects all over the Kingdom for both the private sector and the government facilities such as Govt offices, hotels, Bus stations, hospitals & clinics, religious buildings, heritage sites, universities, and governmental buildings.",
    description1:
      "We are market professionals to undertake a project of any size which includes not only indoor works but also heavy external lines. With a strong team of experienced engineers and technicians, we offer professional expertise in the first, second, and third fix installations, testing, and maintenance services, of electrical systems of various voltage levels.",
    img: electrical_services,
    img1: electrical_services1,
    path: `/services/${"electrical_services"}`,
    cname: "service_submenu",
  },
  {
    id: "plumbing_services",
    title: "Plumbing Services",
    tagline:
      "Available to undertake any project of any size through out the kingdom",
    description:
      "Providing efficient and top-notch plumbing services. We work with a wide range of facilities ranging from residential buildings to private sectors to malls and govt offices. We offer a level of excellence and personalized service. ",
    description1:
      "Our team is comprised of the best plumbers who can take care of almost everything from consultation to the final work. They are also hired by a thorough background check so you can rest assured that when you have plumbing services from us, your project will be completed in the most professional ways.",
    img: plumbing_services,
    img1: plumbing_services1,
    path: `/services/${"plumbing_services"}`,
    cname: "service_submenu",
  },
  {
    id: "equipment_rental",
    title: "Heavy Equipment Rental",
    tagline: "Solving client's requirements with the exact rental equipment",
    description:
      "Our rental equipment includes some of the latest models from the leading manufacturers, all kept in the best possible conditions to meet dynamic requirements and with reliability in mind, our equipment can be provided at competitive rental rates.",
    description1:
      "Our priority is to solve our clients’ business requirements with the exact rental equipment and support services they need, where they need it, and when they need it. We have a fleet of heavy & light equipment which are all certified according to SABIC and SAUDI ARAMCO standards.",
    img: equipment_rental,
    img1: equipment_rental1,
    path: `/services/${"equipment_rental"}`,
    cname: "service_submenu",
  },
  {
    id: "fence_installation",
    title: "Fence Installation",
    tagline: "All types of commercial and industrial fences",
    description:
      "Fencing and Civil works are a major part of our contracting business. Fencing works range from the supply and erection of temporary security, fencing to the supply and erection of high-quality steel, mess, chain link, barbed wire, and wire fencing for both industry and the private sector.",
    description1:
      "Our team has expertise in the use of technical equipment and machinery in the whole fencing process. We are quite fortunate to have a highly efficient team that is the foremost pillar of our strength",
    img: Fence_installation,
    img1: Fence_installation1,
    path: `/services/${"fence_installation"}`,
    cname: "service_submenu",
  },
];

export const nav_items = [
  {
    id: 1,
    title: "Home",
    path: "/",
    cname: "nav-item",
  },
  {
    id: 2,
    title: "About",
    path: "/about",
    cname: "About",
  },
  {
    id: 3,
    title: "Services",
    // path: "",
    cname: "nav-item",
  },
  {
    id: 4,
    title: "Projects",
    path: "/projects",
    cname: "Projects",
  },
  {
    id: 5,
    title: "Gallery",
    path: "/gallery",
    cname: "Gallery",
  },
];
