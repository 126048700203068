import React, { useEffect } from "react";
import "./loader.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Loader() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="preloader">
        <h1 className="arabic_name" data-aos="fade-down">
          {" "}
          شركة خط المجرة للمقاولات
        </h1>
        <h4 className="english_name" data-aos="fade-down">
          Galaxy Line Contracting Company
        </h4>

        <div className="spinner"></div>
      </div>
    </>
  );
}

export default Loader;
