import React, { useEffect } from "react";
import "./services_page.css";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { servicesDropdown } from "../Components/Nav_items";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Services_Page(props) {
  const { name } = useParams();
  const service = servicesDropdown.find((item) => item.id === name);

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 2000 });
  }, [name]);

  return (
    <>
      {service && (
        <>
          <Helmet>
            <title>{service.title} | Galaxy Line Contracting Company</title>
            <meta
              name="description"
              content={`Explore our ${service.title.toLowerCase()} services, where we offer comprehensive solutions for your construction needs. Quality, efficiency, and expertise in every project.`}
            />
            <meta
              name="keywords"
              content={`${service.title.toLowerCase()}, construction services, ${service.id.replace(
                "_",
                " "
              )}, Galaxy Line ${service.id.replace(
                "_",
                " "
              )}, GLCC ${service.id.replace("_", " ")}`}
            />
            <link
              rel="canonical"
              href={`https://www.glccsa.com/services/${service.id}`}
            />
          </Helmet>
          <div className="page_title" id="services_title">
            <div className="background-overlay">
              <h1 data-aos="fade-up">{service.title}</h1>
              <p data-aos="fade-up">Galaxy Line Contracting Company</p>
            </div>
          </div>
          <div className="service_main" id="introduction">
            <div id="services_section1" data-aos="fade-up">
              <h1>{service.title}</h1>
              <div className="line" id="services_line"></div>
              <h3>{service.tagline}</h3>
              <p>{service.description}</p>
              <p>{service.description1}</p>
            </div>
            <div id="services_section2" data-aos="fade-up">
              <img src={service.img} alt={service.title} />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Services_Page;
