import React, { useState } from "react";
import { aboutDropdown } from "./Nav_items";
import { Link } from "react-router-dom";
import "./dropdown.css";

function Dropdown() {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <ul
        className={dropdown ? "about-submenu clicked" : "about-submenu"}
        onClick={() => setDropdown(!dropdown)}
      >
        {aboutDropdown.map((item) => {
          return (
            <Link to={item.path} className="dropdown-link">
              <li
                key={item.id}
                className={item.cname}
                onClick={() => setDropdown(false)}
              >
                {item.title}
              </li>
            </Link>
          );
        })}
      </ul>
    </>
  );
}

export default Dropdown;
