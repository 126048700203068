// src/utils/preloadImages.js
export const preloadImages = (imageUrls) => {
    return new Promise((resolve, reject) => {
      const promises = imageUrls.map((url) => {
        return new Promise((res, rej) => {
          const img = new Image();
          img.src = url;
          img.onload = res;
          img.onerror = rej;
        });
      });
  
      Promise.all(promises)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  };
  