import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./home_page.css";
import aramco_logo from "../Clients_Images/saudi-aramco-logo.png";
import hutichson_port from "../Clients_Images/hutchison_ports.png";
import saudi_electricity_company from "../Clients_Images/Saudi_Electric_Company.png";
import abb_logo from "../Clients_Images/abb-logo.png";
import naizak_logo from "../Clients_Images/naizak-logo.png";
import al_kifah from "../Clients_Images/alkifah.png";
import al_otaishan from "../Clients_Images/al_otaishan.png";
import aurco from "../Clients_Images/aurco.png";
import al_hajri from "../Clients_Images/al_hajri.png";
import { servicesDropdown } from "../Components/Nav_items";
import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet";

export default function Home_page() {
  // Will GO to Top of the page when user will come to this page
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <>

    <Helmet>

    <title>Galaxy Line Contracting Company | Premier Construction Services</title>
    <meta name="description" content="Welcome to Galaxy Line Contracting Company, a leader in providing high-quality construction services. Explore our projects, services, and commitment to excellence." />
    <meta name="keywords" content="GLCC, Galaxyline Contracting Company, Contracting, Construction Solutions, Galaxyline construction solutions, Property Development,خط المجرة,شركة    خط  المجرة   للمقاولات
    ,building materials, BUILDING &amp; RESIDENTIAL, INFRASTRUCTURE,Construction Company,Construction Company in Saudia"/>
    <link rel="canonical" href="https://www.glccsa.com/" />

    </Helmet>

      {/* Hero Section */}
      <div className="hero_section" data-aos="fade-right">
        <h1>
          <span>Building</span> Dreams into Reality
        </h1>

        <Link className="cta" to="/contact">
          <button className="btnn">Get in touch</button>
        </Link>
      </div>
      {/* CEO Message Section */}
      <div className="ceo_back">
        <div className="ceo_pic" data-aos="fade-up"></div>
        <div className="ceo_message" data-aos="fade-up">
          <h1>CEO Message</h1>
          <div className="ceo_text">
            <h4>
              It is with great pleasure that I extend a warm welcome to you on
              behalf of Galaxy Line Contracting Company. As the CEO, I am proud
              to lead the team of dedicated professionals who are committed to
              excellence, innovation and the pursuit of construction excellence.
              Your interest and support are instrumental in our continued growth
              and success. <br />
              <br />
              At Galaxy Line Contracting Company, we understand that building
              the future requires more than bricks and mortar, it demands
              vision, precision and unwavering dedication. We are honored to
              work with different clients at different projects. Every client is
              important for Galaxy Line Contracting Company and we believe in
              building long lasting business relationship with our valuable
              clients.Timely completion, high standard quality work and providing a safe
              working environment are the main pillars of a successfully
              executed work. <br />
              <br />
              Thank you for considering GLCC as your construction partner. We
              look forward to the opportunity to collaborate, build and create
              lasting legacies together.
            </h4>

            <h5 >Warm Regards,</h5>
            <h5 id="ceo_name">Muhammad Naeem Shahzad</h5>
          </div>
        </div>
      </div>
      {/* Services Section */}
      <div className="section_text services_text" data-aos="fade-up">
        <h2>
          Our <span> Services </span>
        </h2>
        <div className="line" id="service_line"></div>
      </div>
      <div id="servicess" data-aos="fade-up">
        {servicesDropdown.map((item, index) => (
          <Link to={item.path} key={index}>
            <div className="services_item">
              <img src={item.img1} alt="" />
              <div id="item_text">
                <h1>{item.title}</h1>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {/* Clients Section */}
      <div className="section_text" id="clients_text" data-aos="fade-up">
        <h2>
          Our <span> Clients </span>
        </h2>
        <div className="line" id="client_lines"></div>
      </div>

      <div className="clients_images" data-aos="fade-up">
        <Marquee gradient={false} speed={70} pauseOnHover>
          <div className="marquee-content">
            <img src={aramco_logo} alt="Saudi Aramco" />
            <img src={hutichson_port} alt="Hutichson Ports" />
            <img
              src={saudi_electricity_company}
              alt="Saudi Electricity Company"
            />
            <img src={abb_logo} alt="ABB Group" />
            <img src={naizak_logo} alt="Naizak" />
            <img src={al_hajri} alt="Al Hajry Overseas Company " />
            <img src={al_kifah} alt="Al Kifah Contracting " />
            <img src={al_otaishan} alt="Al Otaishan Group" />
            <img src={aurco} alt="Aurco Contracting Company" />
          </div>
        </Marquee>
      </div>
    </>
  );
}
