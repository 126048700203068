import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import ProjectPage from "./Pages/Projects";
import HomePage from "./Pages/Home_page";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ContactPage from "./Pages/Contact_page";
import ServicesPage from "./Pages/Services_Page";
import ImagePreloader from "./Components/ImagePreloader";
import ceo_img from "../src/Images/ceo_img.jpg";
import heading from "../src/Images/heading_img.jpg";
import hero_back from "../src/Images/hero_back.jpg";


const GalleryPage = lazy(() => import("./Pages/Gallery"));
const AboutUs = lazy(() => import("./Pages/About_us"));

const imageUrls = [heading, hero_back, ceo_img];

function App() {
  return (
    <Router>
      <ImagePreloader imageUrls={imageUrls}>
        <Suspense
          fallback={
            <div className="spinner_main">
              <div className="spinner"></div>
            </div>
          }
        >
          <Header />
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/projects" element={<ProjectPage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/services/:name" element={<ServicesPage />} />
          </Routes>
          <Footer />
        </Suspense>
      </ImagePreloader>
    </Router>
  );
}

export default App;
