import React, { useEffect } from "react";
import "./projects.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { ProjectsItems } from "../Components/projects_items";
import { Helmet } from "react-helmet";

function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <>
    <Helmet>
    <title>Our Projects | Galaxy Line Contracting Company</title>
    <meta name="description" content="Explore our diverse range of construction projects. From commercial to residential, see how Galaxy Line Contracting Company brings innovative solutions to life." />
    <meta name="keywords" content="construction projects, commercial construction, residential construction, Galaxy Line projects, building projects, GLCC Projects" />
    <link rel="canonical" href="https://www.glccsa.com/projects" />
    </Helmet>
      <div className="page_title">
        <div className="background-overlay">
          <h1 data-aos="fade-up">Our Projects</h1>
          <p data-aos="fade-up">Galaxy Line Contracting Company</p>
        </div>
      </div>

      <section className="cards">
        {ProjectsItems.map((item, index) => {
          return (
            
              <div key={index} className="project_card" data-aos="fade-up">
                <div className="circle-hover">
                  <span className="hover_span">{index + 1}</span>
                </div>
                <h3 className="title">{item.title}</h3>
                <span className="client">
                  <span className="client_name"> Client:&nbsp;</span>{" "}
                  {item.client}
                </span>
                <span className="location">
                  <span className="location_name">Location:&nbsp;</span>
                  {item.location}
                </span>
              </div>
            
          );
        })}
      </section>
    </>
  );
}

export default Projects;
