import React from "react";
import "./footer.css";
import logo from "../Images/glcc_text_logo_transparent.png";
import location_logo from "../Images/location logo.png";
import email_logo from "../Images/email_logo.png";
import call_logo from "../Images/call_logo.png";
import { aboutDropdown, servicesDropdown } from "./Nav_items";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <div className="footer_main">
        <div className="footer">
          <div className="company_info">
            <img src={logo} alt=""></img>
            <h5>
              Galaxy Line Contracting Company is a leading name in the
              construction industry, dedicated to delivering high-quality
              solutions with a commitment to excellence and innovation. We
              specialize in providing reliable and advanced construction
              services tailored to meet diverse client needs. With a strong
              focus on quality, safety, and sustainability, we strive to exceed
              expectations in every project we undertake
            </h5>
          </div>
          <div className="section1">
            <div className="footer_items_div">
              <h1 className="services_heading1">About Us</h1>
              {aboutDropdown.map((item, index) => (
                <Link to={item.path} key={index}>
                  <h3 className="footer_items">{item.title}</h3>
                </Link>
              ))}
            </div>
          </div>
          <div className="section1 section2">
            <div className="footer_items_div">
              <h1 className="services_heading1">Services</h1>
              {servicesDropdown.map((item, index) => (
                <Link to={item.path} key={index}>
                  <h3 className="footer_items footer_items1">{item.title}</h3>
                </Link>
              ))}
            </div>
          </div>
          <div className="section3">
            <h1 className="services_heading1">Contact Info</h1>
            <div className="locationn">
              <img src={location_logo} alt="" />
              <h3>
                Jeddah, Faisaliyah, Al Awwad Center, Po box 23442&nbsp; &nbsp;
                Kingdom of Saudi Arabia
              </h3>
            </div>
            <div className="locationn">
              <img src={call_logo} alt="" />
              <h3>(+966) 53 5060547</h3>
            </div>
            <div className="locationn">
              <img src={email_logo} alt="" />
              <h3>info@glccsa.com</h3>
            </div>
          </div>
        </div>
        <div className="copyright">
          <h6>
            © Copyright 2024 Galaxyline Contracting Company, All Rights Reserved
          </h6>
        </div>
      </div>
    </>
  );
}

export default Footer;
