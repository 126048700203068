export const ProjectsItems = [
  {
    id: 1,
    client: "ABB Contracting Co.",
    title: "132/13.8 KV Sub Station construction work",
    location: "Jizan Medical City",
  },

  {
    id: 2,
    client: "AURCO",
    title: "Construction work of al reef sugar refinery",
    location: " JEC",
  },
  {
    id: 3,
    client: "Al Otaishan Group",
    title:
      "Construction of heat exchanger unit inside aramco refinery including excavation, raft and walls etc..",
    location: " JEC",
  },
  {
    id: 4,
    client: "Domathah Contracting Co",
    title: " Construction work of girls school with all scope of work",
    location: "Al Ardha",
  },
  {
    id: 5,
    client: "United Feed Co",
    title: "Supply & installation of fence",
    location: "Jizan Port",
  },
  {
    id: 6,
    client: "Naizak Global Engineering",
    title:
      "Construction of vessel traffic management information system (vtmis) Tower",
    location: "JEC",
  },
  {
    id: 7,
    client: "Advance Energy Co",
    title: "132/13.8 KV Sub Station construction Works",
    location: "Al Darb",
  },
  {
    id: 8,
    client: "Hutchison Ports",
    title: "Installation of fenders and maintenance work of Jizan Ports etc..",
    location: "Jizan Port",
  },
  {
    id: 9,
    client: "Radisson Blu ",
    title: "Maintenance work of Radisson blu",
    location: "Jizan",
  },
  {
    id: 10,
    client: "Azzam Contracting Co",
    title: "Preparing temporary site offices",
    location: "Jizan University",
  },
  {
    id: 11,
    client: "Al Hajry Overseas Co",
    title: "Maintenance work of Aramco residential camp",
    location: "JEC",
  },
];
