import React, { useEffect, useState } from "react";
import { preloadImages } from "../utils/preloadImages";
import Loader from "./Loader";

const ImagePreloader = ({ imageUrls, children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    preloadImages(imageUrls)
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Failed to preload images:", error);
        setLoading(false);
      });
  }, [imageUrls]);

  if (loading) {
    return <Loader />;
  }

  return <>{children}</>;
};

export default ImagePreloader;
