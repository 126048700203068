import React, { useState } from "react";
import "./header.css";
import email_logo from "../Images/glcc_logo.png";
import Dropdown from "./Dropdown";
import { nav_items } from "./Nav_items";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ServicesDropdown from "./ServicesDropdown";

function Header() {
  const [aboutDropdown, setAboutDropdown] = useState(false);
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const [servicesDropdown1, setServicesDropdown1] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [servicesclickCount, setServicesclickCount] = useState(0);

  const handleServicesClick = () => {
    setServicesclickCount(servicesclickCount + 1);

    if (servicesclickCount % 2 === 0) {
      setServicesDropdown1(true);
    } else {
      setServicesDropdown1(false);
    }
  };

  return (
    <header className="header">
      <div className="nav">
        <Link to="/">
          <img
            src={email_logo}
            alt="Avatar"
            height="90"
            width="170"
            className="logo_images"
            // onClick={toggleMenu}
          />
        </Link>
        {!menuOpen ? (
          <div className="nav1">
            <nav>
              <ul className="nav_links">
                {nav_items.map((item, index) => {
                  if (item.title === "About") {
                    return (
                      <li
                        key={index}
                        className="nav_items"
                        onMouseEnter={() => setAboutDropdown(true)}
                        onMouseLeave={() => setAboutDropdown(false)}
                      >
                        <Link to={item.path}>
                          <div>
                            {item.title}
                            {aboutDropdown && <Dropdown />}
                          </div>
                        </Link>
                      </li>
                    );
                  }
                  if (item.title === "Services") {
                    return (
                      <li
                        key={index}
                        className="nav_items"
                        onMouseEnter={() => setServicesDropdown(true)}
                        onMouseLeave={() => setServicesDropdown(false)}
                      >
                        <Link to={item.path}>
                          <div>
                            {item.title}
                            {servicesDropdown && (
                              <ServicesDropdown menu="false" />
                            )}
                          </div>
                        </Link>
                      </li>
                    );
                  }
                  return (
                    <li key={index} className="nav_items">
                      <Link to={item.path}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        ) : (
          <div className="nav_open">
            
            <ul>
              {nav_items.map((item, index) => {
                if (item.title === "Services") {
                  return (
                    <React.Fragment key={index}>
                      <li onClick={handleServicesClick}>
                        <Link to={item.path}>
                          <div>
                            {item.title}
                            <i
                              className="fa-solid fa-chevron-down"
                              style={{ marginLeft: "4px", marginTop: "1px" }}
                            ></i>
                          </div>
                        </Link>
                      </li>
                      {servicesDropdown1 && (
                        <ServicesDropdown
                          key={`${index}-dropdown`}
                          menu="true"
                          onLinkClick={toggleMenu}
                        />
                      )}
                    </React.Fragment>
                  );
                }
                return (
                  <li key={index} onClick={toggleMenu}>
                    <Link to={item.path}>{item.title}</Link>
                  </li>
                );
              })}
              <li onClick={toggleMenu}>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        )}
        <div className="contact_button1">
          <Link className="cta" to="/contact">
            <button className="btn btn_contact">Contact</button>
          </Link>
        </div>
        <div className="menu_icon" onClick={toggleMenu}>
          <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
        </div>
      </div>
    </header>
  );
}

export default Header;
